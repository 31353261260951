import { connectMemberEditions as connectMemberEditionsAction } from 'Work/actions/memberActions'
import { searchConfig as productionsSearchConfig } from 'Work/config/productionsConfig'
import { first, get, getOr, uniq } from 'lodash/fp'
import { Component, Fragment } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Spinner from 'Common/components/loader/Spinner'
import InfoNotice from 'Common/components/notice/InfoNotice'
import { CloseButton, Dialog, SaveButton } from 'Common/core'
import { Form } from 'Common/form'
import FormContactPicker from 'Common/form/FormContactPicker'
import FormSelectField from 'Common/form/FormSelectField'
import { notEmpty, quickValidation } from 'Common/form/formvalidation'
import { getSearch as getSearchAction } from 'Common/search/searchActions'
import { RootState } from 'Common/types'
import { formSubmit } from 'Common/utils/net/submit'

type Props = {
    closeModal: () => void
    metaData: {
        contactRoles: any[]
    }
    error: string
}
type State = {
    selectedContact: Record<string, unknown> | null
    selectedRole: Record<string, unknown> | null
    isNewContact: boolean
    warningAccepted: boolean
}

const FORM_NAME = 'add_contact_to_editions'

const mapState = (state: RootState) => {
    const { metaData, search } = state
    const productionsSearch = getOr([], productionsSearchConfig.id, search)
    const { itemsTotalCount } = productionsSearch.paging
    const editions = getOr([], 'productions.results', search)
    const initialValues = {
        editions,
    }
    return {
        initialValues,
        metaData: metaData.data,
        editions,
        itemsTotalCount,
    }
}

const form = reduxForm<any, any>({
    form: FORM_NAME,
    validate: quickValidation({
        contact: [notEmpty()],
        roleId: [notEmpty()],
        productionIds: [notEmpty()],
    }),
})
const connector = connect(mapState, {
    connectMemberEditions: connectMemberEditionsAction,
    getSearch: getSearchAction,
})
type PropsFromRedux = ConnectedProps<typeof connector>

class BatchAddContactToEditions extends Component<
    Props & InjectedFormProps & PropsFromRedux,
    State
> {
    state = {
        warningAccepted: false,
        selectedContact: null,
        selectedRole: null,
        isNewContact: false,
        savedProductions: [],
    }

    static defaultProps = {
        selectedEditions: [],
    }

    acceptWarning = () =>
        this.setState({
            warningAccepted: true,
        })

    submit = (values) => {
        const { connectMemberEditions } = this.props
        const { roleId, contact } = values
        const contactId = get('id', contact)
        return formSubmit(() => connectMemberEditions(productionsSearchConfig, contactId, roleId))
    }

    render() {
        const {
            closeModal,
            metaData,
            getSearch,
            editions,
            error,
            handleSubmit,
            submitting,
            valid,
            submitSucceeded,
            itemsTotalCount,
        } = this.props
        const { warningAccepted } = this.state

        const selectedPublishingHouseGroups = uniq(
            editions.map((edition) => edition.document.publishingHouseGroup).filter(() => true),
        )
        const hasMultiplePublishingHouseGroups =
            (selectedPublishingHouseGroups && selectedPublishingHouseGroups.length !== 1) || false
        const selectedPublishingHouseGroup = selectedPublishingHouseGroups
            ? first(selectedPublishingHouseGroups)
            : []

        const closeAndSearchAction = () => {
            getSearch(productionsSearchConfig)
            closeModal()
        }

        const selectedCloseAction = () => {
            submitSucceeded ? closeAndSearchAction() : closeModal()
        }
        const doSubmit = handleSubmit(this.submit)
        const actions = [
            <CloseButton
                variant="outlined"
                key="cancel"
                onClick={selectedCloseAction}
                disabled={submitting}
                style={{
                    marginRight: 10,
                }}
            >
                {submitSucceeded ? 'Close' : 'Cancel'}
            </CloseButton>,
            <SaveButton
                key="save"
                onClick={doSubmit}
                disabled={
                    editions.length === 0 ||
                    !valid ||
                    submitting ||
                    submitSucceeded ||
                    hasMultiplePublishingHouseGroups ||
                    !warningAccepted
                }
            />,
        ]
        const title = <Typography>Connect member to {itemsTotalCount} editions</Typography>
        return (
            <Dialog
                {...{
                    actions,
                    title,
                }}
            >
                {hasMultiplePublishingHouseGroups ? (
                    <Typography>Editions from multiple publishing house groups selected</Typography>
                ) : !hasMultiplePublishingHouseGroups && !warningAccepted ? (
                    <InfoNotice
                        title="Are you sure you want to proceed with a batch operation?"
                        content={
                            <div>
                                <Typography variant="body2">
                                    {`You are about to perform a batch operation that will affect all entities in your search result (${itemsTotalCount} editions). The operation is irreversible.`}
                                </Typography>
                                <Button
                                    style={{
                                        float: 'left',
                                        marginTop: 20,
                                    }}
                                    color="primary"
                                    variant="outlined"
                                    key="accept-warning"
                                    onClick={this.acceptWarning}
                                >
                                    Continue
                                </Button>
                            </div>
                        }
                    />
                ) : warningAccepted &&
                  editions.length !== 0 &&
                  !hasMultiplePublishingHouseGroups ? (
                    <Form onSubmit={doSubmit}>
                        <Grid container spacing={2} xs={12}>
                            {!submitting && !submitSucceeded ? (
                                <Fragment>
                                    <Grid xs={12} sm={6}>
                                        <Field
                                            name="contact"
                                            component={FormContactPicker}
                                            disabled={submitting || submitSucceeded}
                                            label="Contact"
                                            searchId="contactMember"
                                            contactLabel="Contact"
                                            required
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6}>
                                        <Field
                                            name="roleId"
                                            component={FormSelectField}
                                            disabled={submitting || submitSucceeded}
                                            label="Role"
                                            metaData={metaData.contactRoles.filter((contactRole) =>
                                                contactRole.publishingHouseGroupIds.includes(
                                                    selectedPublishingHouseGroup,
                                                ),
                                            )}
                                            required
                                        />
                                    </Grid>
                                </Fragment>
                            ) : null}
                            {submitting ? (
                                <Grid xs={12}>
                                    <div
                                        style={{
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Spinner />
                                        <span>Saving... </span>
                                    </div>
                                </Grid>
                            ) : null}
                            {!submitting && submitSucceeded ? (
                                <Typography variant="body2">Success updating editions.</Typography>
                            ) : null}
                            {error ? (
                                <Grid xs={12}>
                                    <div
                                        style={{
                                            color: '#f44336',
                                            whiteSpace: 'pre-line',
                                        }}
                                    >
                                        {error}
                                    </div>
                                </Grid>
                            ) : null}
                        </Grid>
                    </Form>
                ) : null}
            </Dialog>
        )
    }
}

export default connector(form(BatchAddContactToEditions))
