import { postProductionsBatchActiveWebPeriod as postProductionsBatchActiveWebPeriodAction } from 'Work/actions/productionActiveWebPeriodActions'
import { searchConfig as productionsSearchConfig } from 'Work/config/productionsConfig'
import { getOr } from 'lodash/fp'
import { Component } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { InjectedFormProps, reduxForm } from 'redux-form'

import { Button, MenuItem, Select, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Spinner from 'Common/components/loader/Spinner'
import InfoNotice from 'Common/components/notice/InfoNotice'
import { Dialog, Field, SaveButton } from 'Common/core'
import { Form } from 'Common/form'
import FormDatePicker from 'Common/form/FormDatePicker'
import { notEmpty, quickValidation } from 'Common/form/formvalidation'
import {
    getSearch as getSearchAction,
    updateSearchOptions as updateSearchOptionsAction,
} from 'Common/search/searchActions'
import { RootState } from 'Common/types'
import { formSubmit } from 'Common/utils/net/submit'

import BatchErrorResult from './BatchErrorResult'

const FORM_NAME = 'batch_edit_prodution_active_web_period'

type Props = {
    closeModal: () => void
    selectedEditions: []
    error: string
}
type State = {
    warningAccepted: boolean
    successUpdating: boolean
    productionWarnings: any[]
    selectedWebPeriod: string
}

const mapState = (state: RootState) => {
    const { search } = state
    const productionsSearch = getOr([], productionsSearchConfig.id, search)
    const webPeriods = ['Active web period start', 'Active web period end']
    const { itemsTotalCount } = productionsSearch.paging

    return {
        webPeriods,
        itemsTotalCount,
    }
}

const form = reduxForm<any, any>({
    form: FORM_NAME,
    validate: quickValidation({
        activeWebPeriodId: [notEmpty()],
    }),
})
const connector = connect(mapState, {
    postProductionsBatchActiveWebPeriod: postProductionsBatchActiveWebPeriodAction,
    getSearch: getSearchAction,
    updateSearchOptions: updateSearchOptionsAction,
})
type PropsFromRedux = ConnectedProps<typeof connector>

class BatchEditActiveWebPeriod extends Component<
    Props & InjectedFormProps & PropsFromRedux,
    State
> {
    state = {
        warningAccepted: false,
        successUpdating: false,
        productionWarnings: [],
        selectedWebPeriod: '',
    }

    acceptWarning = () =>
        this.setState({
            warningAccepted: true,
        })

    onHandleChangeWebPeriod = (event) =>
        this.setState({
            selectedWebPeriod: event.target.value,
        })

    submit = (values) => {
        const { postProductionsBatchActiveWebPeriod } = this.props
        const op = values['activeWebPeriod'] ? 'replace' : 'remove'
        const path =
            this.state.selectedWebPeriod === 'Active web period start' ? '/startdate' : '/enddate'
        const value = values['activeWebPeriod']
        return formSubmit(() =>
            postProductionsBatchActiveWebPeriod(productionsSearchConfig, op, path, value),
        )
            .then(({ value }) => value.json)
            .then((result) => {
                const productionWarnings = result.filter((r) => !r.isSuccess)

                if (productionWarnings.length > 0) {
                    this.setState({
                        productionWarnings,
                    })
                } else {
                    this.setState({
                        successUpdating: true,
                    })
                }
            })
    }

    render() {
        const {
            closeModal,
            getSearch,
            error,
            handleSubmit,
            submitting,
            valid,
            submitSucceeded,
            webPeriods,
            itemsTotalCount,
            updateSearchOptions,
        } = this.props
        const { productionWarnings, warningAccepted, successUpdating } = this.state
        const closeAndSearchAction = () => {
            getSearch(productionsSearchConfig)
            closeModal()
        }

        const selectedCloseAction = () => {
            submitSucceeded ? closeAndSearchAction() : closeModal()
        }

        const doSubmit = handleSubmit(this.submit)
        const actions = [
            <Button
                variant="outlined"
                key="cancel"
                onClick={selectedCloseAction}
                color="secondary"
                disabled={submitting}
            >
                {submitSucceeded ? 'Close' : 'Cancel'}
            </Button>,
            <SaveButton
                key="save"
                onClick={doSubmit}
                submitting={submitting}
                disabled={!valid || submitting || submitSucceeded || !warningAccepted}
            />,
        ]
        const title = (
            <Typography>
                {itemsTotalCount > 100 ? (
                    <span style={{ color: 'red' }}>
                        {' '}
                        WARNING - {itemsTotalCount} editions affected
                    </span>
                ) : null}{' '}
                Edit active web period
            </Typography>
        )
        return (
            <Dialog
                {...{
                    actions,
                    title,
                }}
            >
                <Form onSubmit={doSubmit}>
                    {!warningAccepted ? (
                        <InfoNotice
                            title="Are you sure you want to proceed with a batch operation?"
                            content={
                                <div>
                                    <Typography variant="body2">
                                        {`You are about to perform a batch operation that will affect all entities in your search result (${itemsTotalCount} editions). The operation is irreversible.`}
                                    </Typography>
                                    <Button
                                        color="primary"
                                        variant="outlined"
                                        key="accept-warning"
                                        onClick={this.acceptWarning}
                                    >
                                        Continue
                                    </Button>
                                </div>
                            }
                        />
                    ) : (
                        !submitting &&
                        warningAccepted &&
                        !successUpdating &&
                        (productionWarnings.length === 0 ? (
                            <>
                                <Select
                                    variant="standard"
                                    label="Active Web Period"
                                    value={this.state.selectedWebPeriod}
                                    onChange={this.onHandleChangeWebPeriod}
                                    style={{
                                        width: '50%',
                                    }}
                                >
                                    {webPeriods.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {this.state.selectedWebPeriod ? (
                                    <Grid xs={6}>
                                        <Field
                                            name="activeWebPeriod"
                                            component={FormDatePicker}
                                            label={this.state.selectedWebPeriod}
                                        />
                                    </Grid>
                                ) : null}
                            </>
                        ) : null)
                    )}
                    {productionWarnings.length > 0 ? (
                        <BatchErrorResult
                            {...{
                                productionWarnings,
                                updateSearchOptions,
                            }}
                        />
                    ) : null}
                    {!submitting && successUpdating ? (
                        <Typography variant="body2">Success updating editions.</Typography>
                    ) : null}
                    {submitting ? (
                        <Grid xs={12}>
                            <div
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                <Spinner />
                                <span>Saving... </span>
                            </div>
                        </Grid>
                    ) : null}
                    {error ? (
                        <Grid xs={12}>
                            <div
                                style={{
                                    textAlign: 'center',
                                    color: '#f44336',
                                }}
                            >
                                {error}
                            </div>
                        </Grid>
                    ) : null}
                </Form>
            </Dialog>
        )
    }
}

export default connector(form(BatchEditActiveWebPeriod))
