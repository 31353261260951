import { Component } from 'react'

import ErrorDialog from '../error/ErrorDialog'
import messages from '../messages'

type Props = {
    title?: string
    buttonLabel?: string
    submitting?: boolean
    submitFailed?: boolean
    error?: string
}

export default class FormErrorDialog extends Component<Props> {
    static defaultProps = {
        title: messages.error.genericError,
        buttonLabel: 'Close',
    }

    state = {
        open: false,
        handled: false,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { submitting } = this.props
        const handled = !submitting && nextProps.submitting ? false : this.state.handled
        const open = nextProps.submitFailed && nextProps.error && !handled
        this.setState({
            open,
            handled,
        })
    }

    handleClose = () => {
        this.setState({
            open: false,
            handled: true,
        })
    }

    render() {
        if (!this.state.open) return null
        const { error, title, buttonLabel } = this.props
        return (
            <ErrorDialog
                handleClose={this.handleClose}
                {...{
                    error,
                    title,
                    buttonLabel,
                }}
            />
        )
    }
}
