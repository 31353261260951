import errorcodes from './errorcodes'
import error from './errors'
import validation from './validation'

const messages = {
    error,
    validation,
    errorcodes,
}
export default messages
