import {
    deleteCustomPropertyItem as deleteCustomPropertyItemAction,
    patchCustomPropertyItem as patchCustomPropertyItemAction,
} from 'Metadata/actions/metaDataTypeActions'
import { getOr, pick } from 'lodash/fp'
import { Component } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { InjectedFormProps, reduxForm } from 'redux-form'

import Grid from '@mui/material/Unstable_Grid2'

import ConfirmationWrapper from 'Common/components/confirmation/ConfirmationWrapper'
import DisplayField from 'Common/components/displayFields/DisplayField'
import Spinner from 'Common/components/loader/Spinner'
import { Dialog, Field, RemoveButton, SaveButton } from 'Common/core'
import { Form } from 'Common/form'
import FormErrorDialog from 'Common/form/FormErrorDialog'
import FormTextField from 'Common/form/FormTextField'
import { isInteger, isPositiveNumber, notEmpty, quickValidation } from 'Common/form/formvalidation'
import { RootState } from 'Common/store/createStore'
import { createJsonPatch } from 'Common/utils/json'
import { formSubmit } from 'Common/utils/net/submit'

const getCustomPropertyTypeName = (typeId, customPropertyTypes) =>
    getOr(
        '-',
        'name',
        customPropertyTypes.find((_) => _.id === typeId),
    )

type Props = {
    onCloseModal(): void
    typeId: number
    metadataItemId: number
    item: Record<string, unknown>
    customPropertyTypes?: unknown[]
}

const FORM_NAME = 'editcustompropertyitemmodal'

const mapState = (state: RootState, props: Props) => {
    const { item } = props
    const patchBase = pick(['customPropertyTypeId', 'value', 'sortOrder', 'metadataItemId'], item)
    const initialValues = { ...patchBase }
    return {
        initialValues,
        patchBase,
    }
}

const mapDisp = {
    patchCustomPropertyItem: patchCustomPropertyItemAction,
    deleteCustomPropertyItem: deleteCustomPropertyItemAction,
}
const connector = connect(mapState, mapDisp)

type PropsFromRedux = ConnectedProps<typeof connector>

const form = reduxForm<any, any>({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: quickValidation({
        value: [notEmpty()],
        sortOrder: [isPositiveNumber(), isInteger()],
    }),
})
type State = {
    showDeleteConfirmation: boolean
}
class EditCustomPropertyItemModal extends Component<
    Props & InjectedFormProps & PropsFromRedux,
    State
> {
    state = {
        showDeleteConfirmation: false,
    }

    submit = (values) => {
        const { typeId, item, patchCustomPropertyItem, patchBase, metadataItemId, onCloseModal } =
            this.props
        return formSubmit(() =>
            patchCustomPropertyItem(
                typeId,
                metadataItemId,
                item.id,
                createJsonPatch(patchBase, values),
            ),
        ).then(() => onCloseModal())
    }

    submitDelete = () => {
        const { typeId, item, deleteCustomPropertyItem, metadataItemId, onCloseModal } = this.props
        return formSubmit(() => deleteCustomPropertyItem(typeId, metadataItemId, item.id)).then(
            () => onCloseModal(),
        )
    }

    toggleDeleteConfirmation = () =>
        this.setState((state) => ({
            showDeleteConfirmation: !state.showDeleteConfirmation,
        }))

    render() {
        const { submitting, dirty, item, onCloseModal, handleSubmit, customPropertyTypes } =
            this.props
        const { showDeleteConfirmation } = this.state
        const doSubmit = handleSubmit(this.submit)
        const doDelete = handleSubmit(this.submitDelete)
        return (
            <Dialog
                title="Edit custom property item"
                actions={[
                    <RemoveButton
                        key="remove"
                        onClick={this.toggleDeleteConfirmation}
                        submitting={submitting}
                        disabled={submitting || showDeleteConfirmation}
                    ></RemoveButton>,
                    <SaveButton
                        key="save"
                        onClick={doSubmit}
                        submitting={submitting}
                        disabled={!dirty || submitting || showDeleteConfirmation}
                    ></SaveButton>,
                ]}
                onClose={() => onCloseModal()}
            >
                <Form onSubmit={doSubmit}>
                    <Grid container spacing={2} xs={12}>
                        <ConfirmationWrapper
                            confirmAction={doDelete}
                            cancelAction={this.toggleDeleteConfirmation}
                            show={showDeleteConfirmation}
                            showSpinner={submitting}
                        >
                            <Grid xs={12} sm={6}>
                                <DisplayField
                                    id="Metadata.EditCustomPropertyItem.CustomPropertyTypeId"
                                    value={getCustomPropertyTypeName(
                                        item.customPropertyTypeId,
                                        customPropertyTypes,
                                    )}
                                    label="Custompropertytype"
                                />
                            </Grid>
                            <Grid xs={6}>
                                <Field
                                    id="Metadata.EditCustomPropertyItem.Value"
                                    name="value"
                                    component={FormTextField}
                                    label="Value"
                                    required
                                />
                            </Grid>
                            <Grid xs={6}>
                                <Field
                                    id="Metadata.EditCustomPropertyItem.SortOrder"
                                    name="sortOrder"
                                    component={FormTextField}
                                    label="Sort order"
                                    type="number"
                                />
                            </Grid>

                            {submitting ? <Spinner /> : null}
                        </ConfirmationWrapper>
                    </Grid>
                </Form>
                <FormErrorDialog {...this.props} />
            </Dialog>
        )
    }
}

export default connector(form(EditCustomPropertyItemModal))
