import {
    deleteMetaDataItem as deleteMetaDataItemAction,
    patchMetaDataItem as patchMetaDataItemAction,
} from 'Metadata/actions/metaDataTypeActions'
import { pick } from 'lodash/fp'
import { Component } from 'react'
import { ConnectedProps, connect } from 'react-redux'
import { InjectedFormProps, reduxForm } from 'redux-form'

import Grid from '@mui/material/Unstable_Grid2'

import ConfirmationWrapper from 'Common/components/confirmation/ConfirmationWrapper'
import Spinner from 'Common/components/loader/Spinner'
import { Dialog, Field, RemoveButton, SaveButton } from 'Common/core'
import { Form } from 'Common/form'
import FormCheckbox from 'Common/form/FormCheckbox'
import FormErrorDialog from 'Common/form/FormErrorDialog'
import FormSelectField from 'Common/form/FormSelectField'
import FormTextField from 'Common/form/FormTextField'
import { isInteger, isPositiveNumber, notEmpty, quickValidation } from 'Common/form/formvalidation'
import { RootState } from 'Common/store/createStore'
import { createJsonPatch } from 'Common/utils/json'
import { formSubmit } from 'Common/utils/net/submit'

type Props = {
    onCloseModal(): void
    item: Record<string, unknown>
}

const FORM_NAME = 'editmetadataitemform'

const mapState = (state: RootState, props: Props) => {
    const { item } = props
    const { metaData } = state
    const patchBase = pick(
        ['name', 'externalId', 'sortOrder', 'description', 'isActive', 'showExternal'],
        item,
    )
    const initialValues = { ...patchBase }
    return {
        initialValues,
        metaData: metaData.data,
        patchBase,
    }
}

const connector = connect(mapState, {
    patchMetaDataItem: patchMetaDataItemAction,
    deleteMetaDataItem: deleteMetaDataItemAction,
})

type PropsFromRedux = ConnectedProps<typeof connector>

const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate: quickValidation({
        name: [notEmpty()],
        externalId: [notEmpty()],
        sortOrder: [isPositiveNumber(), isInteger()],
    }),
})

type State = {
    showDeleteConfirmation: boolean
}

class EditMetaDataItemModal extends Component<Props & InjectedFormProps & PropsFromRedux, State> {
    state = {
        showDeleteConfirmation: false,
    }

    submit = (values) => {
        const { item, patchBase, patchMetaDataItem, onCloseModal } = this.props
        return formSubmit(() =>
            patchMetaDataItem(item.metadataTypeId, item.id, createJsonPatch(patchBase, values)),
        ).then(() => onCloseModal())
    }

    submitDelete = () => {
        const { item, deleteMetaDataItem, onCloseModal } = this.props
        return formSubmit(() => deleteMetaDataItem(item.metadataTypeId, item.id)).then(() =>
            onCloseModal(),
        )
    }

    toggleDeleteConfirmation = () =>
        this.setState((state) => ({
            showDeleteConfirmation: !state.showDeleteConfirmation,
        }))

    render() {
        const { submitting, dirty, onCloseModal, handleSubmit, metaData } = this.props
        const { showDeleteConfirmation } = this.state
        const { publishingHouseGroups } = metaData
        const doSubmit = handleSubmit(this.submit)
        const doDelete = handleSubmit(this.submitDelete)
        return (
            <Dialog
                title="Edit meta data item"
                actions={[
                    <RemoveButton
                        key="remove"
                        onClick={this.toggleDeleteConfirmation}
                        submitting={submitting && showDeleteConfirmation}
                        disabled={submitting || showDeleteConfirmation}
                    />,
                    <SaveButton
                        key="save"
                        onClick={doSubmit}
                        submitting={submitting}
                        disabled={!dirty || submitting || showDeleteConfirmation}
                    />,
                ]}
                onClose={() => onCloseModal()}
            >
                <div>
                    <Form onSubmit={doSubmit}>
                        <Grid container spacing={2}>
                            <ConfirmationWrapper
                                confirmAction={doDelete}
                                cancelAction={this.toggleDeleteConfirmation}
                                show={showDeleteConfirmation}
                                showSpinner={submitting}
                            >
                                <Grid xs={6}>
                                    <Field
                                        id="Metadata.EditMetaDataItem.Name"
                                        name="name"
                                        component={FormTextField}
                                        label="Name"
                                        required
                                    />
                                </Grid>
                                <Grid xs={6}>
                                    <Field
                                        id="Metadata.EditMetaDataItem.ExternalId"
                                        name="externalId"
                                        component={FormTextField}
                                        label="External ID"
                                        required
                                    />
                                </Grid>
                                <Grid xs={12}>
                                    <Field
                                        id="Metadata.EditMetaDataItem.Description"
                                        name="description"
                                        component={FormTextField}
                                        label="Description"
                                        multiline
                                    />
                                </Grid>
                                <Grid xs={6}>
                                    <Field
                                        id="Metadata.EditMetaDataItem.SortOrder"
                                        name="sortOrder"
                                        component={FormTextField}
                                        label="Sort order"
                                        type="number"
                                    />
                                </Grid>
                                <Grid xs={6}>
                                    <Field
                                        id="Metadata.EditMetaDataItem.PublishingHouseGroupId"
                                        name="publishingHouseGroupId"
                                        component={FormSelectField}
                                        label="Publishing house group"
                                        metaData={publishingHouseGroups}
                                    />
                                </Grid>
                                <Grid xs={6}>
                                    <Field
                                        id="Metadata.EditMetaDataItem.IsActive"
                                        name="isActive"
                                        component={FormCheckbox}
                                        label="Active"
                                    />
                                </Grid>
                                <Grid xs={6}>
                                    <Field
                                        id="Metadata.EditMetaDataItem.ShowExternal"
                                        name="showExternal"
                                        component={FormCheckbox}
                                        label="Show external"
                                    />
                                </Grid>
                                {submitting ? <Spinner /> : null}
                            </ConfirmationWrapper>
                        </Grid>
                    </Form>
                    <FormErrorDialog {...this.props} />
                </div>
            </Dialog>
        )
    }
}
export default connector(form(EditMetaDataItemModal))
