import { services } from 'Common/environment'
import { AppDispatch } from 'Common/store/createStore'
import request from 'Common/utils/net/request'

export const PATCH_USER = 'PATCH_USER'
export const PATCH_USER_REQUEST = 'PATCH_USER_REQUEST'
export const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS'
export const PATCH_USER_FAILURE = 'PATCH_USER_FAILURE'

export const patchUser = (id: string, patchSet: any) => (dispatch: AppDispatch) =>
    dispatch({
        type: PATCH_USER,
        meta: {
            id,
        },
        payload: {
            promise: request({
                url: services.users(`/v1/users/${id}`),
                method: 'PATCH',
                body: patchSet,
            }),
        },
    })
