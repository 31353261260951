import { useState } from 'react'

import { Button, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import Spinner from '../loader/Spinner'

type Props = {
    children?: any
    confirmAction(..._args: unknown[]): unknown
    cancelAction(..._args: unknown[]): unknown
    show?: boolean
    message?: React.ReactNode
    showSpinner?: boolean
}

const ConfirmationWrapper = (props: Props): JSX.Element => {
    const { children, show, message, showSpinner, confirmAction, cancelAction } = props
    const [isClicked, setIsClicked] = useState(false)

    const handleConfirmAction = () => {
        setIsClicked(true)
        confirmAction()
    }

    if (!show) return children
    return (
        <Grid container spacing={2} xs={12}>
            <Grid xs={12} display="flex" justifyContent="center">
                <Typography variant="h5">{message}</Typography>
            </Grid>
            {!showSpinner ? (
                <Grid container spacing={2} xs={12} display="flex" justifyContent="center">
                    <Grid xs={2} display="flex" justifyContent="center">
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleConfirmAction}
                            color="primary"
                            disabled={isClicked}
                        >
                            Yes
                        </Button>
                    </Grid>
                    <Grid xs={2} display="flex" justifyContent="center">
                        <Button fullWidth variant="outlined" color="error" onClick={cancelAction}>
                            No
                        </Button>
                    </Grid>
                </Grid>
            ) : null}
            {showSpinner ? <Spinner /> : null}
        </Grid>
    )
}

ConfirmationWrapper.defaultProps = {
    show: false,
    message: 'Are you sure you want to delete this?',
    showSpinner: false,
}

export default ConfirmationWrapper
