import { Component } from 'react'

import { Button } from '@mui/material'

import { Dialog } from 'Common/core'

import messages from '../../messages'

type Props = {
    handleClose(..._args: unknown[]): unknown
    title?: string
    buttonLabel?: string
    error?: string
}

export default class GenericErrorDialog extends Component<Props> {
    static defaultProps = {
        title: messages.error.genericError,
        buttonLabel: 'close',
    }

    render() {
        const { error, title, buttonLabel, handleClose } = this.props
        if (!error) return null
        return (
            <Dialog
                title={title}
                actions={[
                    <Button variant="outlined" key="buttonLabel" onClick={handleClose}>
                        {buttonLabel}
                    </Button>,
                ]}
                onClose={handleClose}
            >
                {error}
            </Dialog>
        )
    }
}
