const queryKeys = {
    agreementListState: 'agreementListState',
    auth: 'auth',
    agreementsSearch: 'agreementsSearch',
    agreementsActive: 'agreementsActive',
    agreementsInactive: 'agreementsInactive',
    agreementsSearchWork: 'agreementsSearchWork',
    assetDownload: 'assetDownload',
    assetExport: 'assetExport',
    assetTypes: 'assetTypes',
    batteryInfoOptions: 'batteryInfoOptions',
    brands: 'brands',
    contactConcepts: 'contactConcepts',
    contactEditions: 'contactEditions',
    contactWorks: 'contactWorks',
    contactAgreements: 'contactAgreements',
    claimTypes: 'claimTypes',
    claimValues: 'claimValues',
    contact: 'contact',
    companyContacts: 'companyContacts',
    costtypes: 'costtypes',
    costCenters: 'costCenters',
    distributionAssignments: 'distributionAssignments',
    deliveryTypes: 'deliveryTypes',
    emailTemplate: 'emailTemplate',
    emailTemplates: 'emailTemplates',
    errorReport: 'errorReport',
    isbnSeries: 'isbnSeries',
    importTransactions: 'importTransactions',
    importStockChanges: 'importStockChanges',
    metadataPrinterPrices: 'metadataPrinterPrice',
    oneflowContracts: 'workContracts',
    oneflowTemplate: 'oneflowTemplate',
    statementPdfTemplates: 'statementPdfTemplates',
    paymentInformationUpcoming: 'paymentInformationUpcoming',
    paymentInformationHistory: 'paymentInformationHistory',
    productionDetailOptions: 'productionDetailOptions',
    products: 'products',
    productReplacement: 'productReplacement',
    publishingHouses: 'publishingHouses',
    publishingHouseGroups: 'publishingHouseGroups',
    receiverReport: 'receiverReport',
    reportExport: 'reportExport',
    retailers: 'retailers',
    salesChannels: 'salesChannels',
    salesChannelTranslations: 'salesChannelTranslations',
    settlements: 'settlements',
    salesTransactions: 'salesTransactions',
    searchContact: 'searchContact',
    searchAssetContractWork: 'searchAssetContractWork',
    searchAssetContractEditions: 'searchAssetContractEditions',
    searchEdition: 'searchEdition',
    searchEditionsByWorkIds: 'searchEditionsByWorkIds',
    searchThema: 'searchThema',
    searchWork: 'searchWork',
    settingsErp: 'settingsErp',
    settingEducation: 'settingEducation',
    settlementPeriod: 'settlementPeriod',
    standardCosts: 'standardCosts',
    statement: 'statement',
    statementReports: 'statementReports',
    upcomingSettlements: 'upcomingSettlements',
    userContacts: 'userContacts',
    work: 'work',
}

export default queryKeys

export const workCommunicationPlanKeys = {
    all: ['communicationPlan'] as const,
    details: () => [...workCommunicationPlanKeys.all, 'detail'] as const,
    detail: (id: number) => [...workCommunicationPlanKeys.details(), id] as const,
}

export const workLaunchPlanKeys = {
    all: ['launchPlan'] as const,
    details: () => [...workLaunchPlanKeys.all, 'detail'] as const,
    detail: (id: number) => [...workLaunchPlanKeys.details(), id] as const,
}
export const workLaunchPlanActivitiesKeys = {
    all: ['launchPlanActivities'] as const,
    details: () => [...workLaunchPlanActivitiesKeys.all, 'detail'] as const,
    detail: (id: number) => [...workLaunchPlanActivitiesKeys.details(), id] as const,
}

export const workRoyaltyKeys = {
    all: ['workRoyalty'] as const,
    details: () => [...workRoyaltyKeys.all, 'detail'] as const,
    detail: (id: number) => [...workRoyaltyKeys.details(), id] as const,
}

export const workReviewQuotesKeys = {
    all: ['workReviewQuotes'] as const,
    details: () => [...workReviewQuotesKeys.all, 'detail'] as const,
    detail: (id: number) => [...workReviewQuotesKeys.details(), id] as const,
}

export const editionAssetsKeys = {
    all: ['editionAssets'] as const,
    lists: () => [...editionAssetsKeys.all, 'list'] as const,
    list: (id: number) => [...editionAssetsKeys.lists(), { id }] as const,
}

export const editionPrintChangeKeys = {
    all: ['editionPrintChanges'] as const,
    lists: () => [...editionPrintChangeKeys.all, 'list'] as const,
    list: (id: number) => [...editionPrintChangeKeys.lists(), { id }] as const,
}

export const editionTextsKeys = {
    all: ['editionTexts'] as const,
    lists: () => [...editionTextsKeys.all, 'list'] as const,
    list: (id: number) => [...editionTextsKeys.lists(), { id }] as const,
}

export const editionEducationKeys = {
    all: ['editionEducation'] as const,
    lists: () => [...editionEducationKeys.all, 'list'] as const,
    list: (id: number) => [...editionEducationKeys.lists(), { id }] as const,
}

export const workAssetsKeys = {
    all: ['workAssets'] as const,
    lists: () => [...workAssetsKeys.all, 'list'] as const,
    list: (id: number) => [...workAssetsKeys.lists(), { id }] as const,
}

export const workThemasKeys = {
    all: ['workThemas'] as const,
    lists: () => [...workThemasKeys.all, 'list'] as const,
    list: (id: number) => [...workThemasKeys.lists(), { id }] as const,
}

export const advanceKeys = {
    all: ['advances'] as const,
    lists: () => [...advanceKeys.all, 'list'] as const,
    list: (id: number) => [...advanceKeys.lists(), { id }] as const,
    details: () => [...advanceKeys.all, 'detail'] as const,
    detail: (id: number) => [...advanceKeys.details(), id] as const,
}

export const standardCostKeys = {
    all: ['standardCosts'] as const,
    lists: () => [...standardCostKeys.all, 'list'] as const,
    list: (id: number) => [...standardCostKeys.lists(), { id }] as const,
    details: () => [...standardCostKeys.all, 'detail'] as const,
    detail: (id: number) => [...standardCostKeys.details(), id] as const,
}

export const agreementKeys = {
    all: ['agreements'] as const,
    lists: () => [...agreementKeys.all, 'list'] as const,
    list: (id: number) => [...agreementKeys.lists(), { id }] as const,
    details: () => [...agreementKeys.all, 'detail'] as const,
    detail: (id: number) => [...agreementKeys.details(), id] as const,
}

export const deliverySpecificationKeys = {
    all: ['deliverySpecification'] as const,
    lists: () => [...deliverySpecificationKeys.all, 'list'] as const,
    list: (id: number) => [...deliverySpecificationKeys.lists(), { id }] as const,
    details: () => [...deliverySpecificationKeys.all, 'detail'] as const,
    detail: (id: number) => [...deliverySpecificationKeys.details(), id] as const,
}

export const printChangesKeys = {
    all: ['printChanges'] as const,
    lists: () => [...printChangesKeys.all, 'list'] as const,
    list: (id: number) => [...printChangesKeys.lists(), { id }] as const,
    details: () => [...printChangesKeys.all, 'detail'] as const,
    detail: (id: number) => [...printChangesKeys.details(), id] as const,
}

export const editionKeys = {
    all: ['edition'] as const,
    lists: () => [...editionKeys.all, 'list'] as const,
    list: (id: number) => [...editionKeys.lists(), { id }] as const,
    details: () => [...editionKeys.all, 'detail'] as const,
    detail: (id: number) => [...editionKeys.details(), id] as const,
}

export const contactKeys = {
    all: ['contact'] as const,
    lists: () => [...contactKeys.all, 'list'] as const,
    list: (id: number) => [...contactKeys.lists(), { id }] as const,
    details: () => [...contactKeys.all, 'detail'] as const,
    detail: (id: number) => [...contactKeys.details(), id] as const,
}

export const workKeys = {
    all: ['work'] as const,
    lists: () => [...workKeys.all, 'list'] as const,
    list: (id: number) => [...workKeys.lists(), { id }] as const,
    details: () => [...workKeys.all, 'detail'] as const,
    detail: (id: number) => [...workKeys.details(), id] as const,
}

export const termsKeys = {
    all: ['terms'] as const,
    lists: () => [...termsKeys.all, 'list'] as const,
    list: (id: number) => [...termsKeys.lists(), { id }] as const,
    details: () => [...termsKeys.all, 'detail'] as const,
    detail: (id: number) => [...termsKeys.details(), id] as const,
}

export const bundleKeys = {
    all: ['bundle'] as const,
    lists: () => [...bundleKeys.all, 'list'] as const,
    list: (id: string) => [...bundleKeys.lists(), { id }] as const,
    erps: () => [...bundleKeys.all, 'erp'] as const,
    erp: (id: string) => [...bundleKeys.erps(), { id }] as const,
}

export const assetThumbnailKeys = {
    all: ['assetThumbnail'] as const,
    lists: () => [...assetThumbnailKeys.all, 'list'] as const,
    list: (id: number) => [...assetThumbnailKeys.lists(), { id }] as const,
}

export const userKeys = {
    all: ['user'] as const,
    lists: () => [...userKeys.all, 'list'] as const,
    list: (id: string) => [...userKeys.lists(), { id }] as const,
    details: () => [...userKeys.all, 'detail'] as const,
    detail: (id: string) => [...userKeys.details(), id] as const,
}

export const seriesKeys = {
    all: ['series'] as const,
    lists: () => [...seriesKeys.all, 'list'] as const,
    list: (id: string) => [...seriesKeys.lists(), { id }] as const,
    details: () => [...seriesKeys.all, 'detail'] as const,
    detail: (id: string) => [...seriesKeys.details(), id] as const,
}
