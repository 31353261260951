import { searchConfig as assetsSearchConfig } from 'Asset/config/assetsConfig'
import { searchConfig as contactsSearchConfig } from 'Contact/config/contactsConfig'
import { searchConfig as activitiesSearchConfig } from 'Work/config/activitiesConfig'
import { searchConfig as productionsSearchConfig } from 'Work/config/productionsConfig'
import { searchConfig as worksSearchConfig } from 'Work/config/worksConfig'
import { NavLink } from 'react-router-dom'

import IconRoyalty from '@mui/icons-material/AccountBalance'
import IconBundle from '@mui/icons-material/AutoAwesomeMotion'
import IconEdition from '@mui/icons-material/Book'
import IconBuild from '@mui/icons-material/Build'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import IconCloudDownload from '@mui/icons-material/CloudDownload'
import EuroIcon from '@mui/icons-material/EuroSymbol'
import IconEvent from '@mui/icons-material/Event'
import IconFace from '@mui/icons-material/Face'
import IconSeries from '@mui/icons-material/FolderCopy'
import IconAssets from '@mui/icons-material/Image'
import IconWork from '@mui/icons-material/LibraryBooks'
import IconConcept from '@mui/icons-material/LightbulbOutlined'
import IconListAlt from '@mui/icons-material/ListAlt'
import IconUpload from '@mui/icons-material/Publish'
import IconSettings from '@mui/icons-material/Settings'
import IconSuperVisor from '@mui/icons-material/SupervisorAccount'
import {
    Divider,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    SvgIcon,
    Tooltip,
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import { CSSObject, Theme, styled, useTheme } from '@mui/material/styles'

import { getSearchUrl } from 'Common/search/urls'
import { hasClaim, hasClaimWithValue, isCustomerAdmin, isOpusAdmin } from 'Common/utils/user/auth'

const drawerWidth = 230

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
})

const DrawerHeader = styled('div')(({ theme }) => ({
    ...theme.mixins.toolbar,
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

type Props = {
    open: boolean
    productions: any
    works: any
    activities: any
    contacts: any
    assets: any
    auth: any
    handleDrawerClose: () => void
}

const MainNavigation = (props: Props) => {
    const { productions, works, activities, handleDrawerClose, contacts, assets, auth } = props

    const theme = useTheme()

    const baseDrawerItems = [
        {
            title: 'My work',
            link: '/my-work',
            icon: function MockingbirdIcon() {
                return (
                    <SvgIcon viewBox="0 0 164.84 161.8">
                        <path
                            style={{
                                fill: 'none',
                                stroke: '#0000008a',
                                strokeMiterlimit: 1,
                                strokeWidth: 12.27,
                            }}
                            d="M154,46.18c-4.22-1.42-5.21-5.5-11.2-8.13a24.82,24.82,0,0,0-19.16.25c-9.2,4-11.85,8.69-22.73,9.57C69.38,47.88,72,11.4,87.2,0c-36.84,7.93-29.63,47-32,46.92-9.11-9.62-2.72-27.67-2.72-27.67-16.42,9.6-27.18,37.59-10.1,61.46,0,0-6.66.15-11.88-15.42-2.86,22.61,18,40.81,36.74,34-7.75,29.3-49.26,40.39-64.9,36.27,3,9.1,24.22,8,23.89,7.95,0,0-5.26,9.11-26.23,12.79,29.85,22.6,71-31.35,88.71-36.25,18.05-5.32,42.51-11.37,53.12-52.88,2.53-8.53,7.37-12.22,12.88-14.47,6.61-2.22,10.17-2.18,10.13-2.17S162.81,48.91,154,46.18Zm-13.48,4.45a2.58,2.58,0,1,1,2.32-2.8A2.59,2.59,0,0,1,140.54,50.63Z"
                        />
                    </SvgIcon>
                )
            },
        },
    ]

    const drawerItems = [
        {
            title: 'Royalty',
            link: '/royalty/agreements',
            icon: IconRoyalty,
            isActive: hasClaimWithValue(auth, 'opus_royalty', 'user'),
        },
        {
            title: 'Concepts',
            link: '/concept/search',
            icon: IconConcept,
            isActive: true,
        },
        {
            title: 'Works',
            link: getSearchUrl(works, worksSearchConfig),
            icon: IconWork,
            isActive: true,
        },
        {
            title: 'Editions',
            link: getSearchUrl(productions, productionsSearchConfig),
            icon: IconEdition,
            isActive: true,
        },
        {
            title: 'Bundles',
            link: '/bundle/list',
            icon: IconBundle,
            isActive: true,
        },
        {
            title: 'Series',
            link: '/series/list',
            icon: IconSeries,
            isActive: hasClaimWithValue(auth, 'opus_user', 'admin'),
        },
        {
            title: 'Activities',
            link: getSearchUrl(activities, activitiesSearchConfig),
            icon: IconEvent,
            isActive: true,
        },
        {
            title: 'Assets',
            link: getSearchUrl(assets, assetsSearchConfig),
            icon: IconAssets,
            isActive: true,
        },
        {
            title: 'Contacts',
            link: getSearchUrl(contacts, contactsSearchConfig),
            icon: IconSuperVisor,
            isActive: true,
        },
        {
            title: 'Selection lists',
            link: '/contact/lists',
            icon: IconListAlt,
            isActive: true,
        },
        {
            title: 'Downloads',
            link: '/mydownloads',
            icon: IconCloudDownload,
            isActive: true,
        },
    ].filter((_) => _.isActive)

    const accessBasedDrawerItems = [
        {
            title: 'Imports',
            link: '/imports',
            icon: IconUpload,
            isActive: hasClaim(auth, 'opus_royalty') || hasClaim(auth, 'opus_integration'),
        },
    ].filter((_) => _.isActive)

    const adminDrawerItems = [
        {
            title: 'Admin',
            link: '/admin',
            icon: IconSettings,
            isActive: isCustomerAdmin(auth),
        },
        {
            title: 'Users',
            link: '/users',
            icon: IconFace,
            isActive: hasClaimWithValue(auth, 'opus_user', 'admin'),
        },
        {
            title: 'Metadata',
            link: '/metadata',
            icon: IconBuild,
            isActive: hasClaimWithValue(auth, 'opus_metadata', 'admin'),
        },
        {
            title: 'Update prices',
            link: '/admin/price-update',
            icon: EuroIcon,
            isActive: isOpusAdmin(auth),
        },
    ].filter((_) => _.isActive)
    return (
        <Drawer
            variant="permanent"
            anchor="left"
            open={props.open}
            sx={{ display: { xs: 'flex' }, displayPrint: 'none' }}
        >
            <DrawerHeader>
                <IconButton sx={{ marginTop: 1, marginLeft: 2 }} onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List key="base-desktop">
                {baseDrawerItems.map((item) => {
                    const IconComponent = item.icon
                    return (
                        <Tooltip
                            key={`base-desktop-${item.title}`}
                            title={item.title}
                            disableHoverListener={props.open}
                            placement="right"
                        >
                            <ListItem disablePadding>
                                <ListItemButton component={NavLink} to={item.link}>
                                    <ListItemIcon>
                                        <IconComponent />
                                    </ListItemIcon>
                                    {props.open && <ListItemText primary={item.title} />}
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    )
                })}
            </List>
            <Divider />
            <List
                sx={{
                    ['& .active']: {
                        background: theme.palette.divider,
                    },
                }}
                key="desktop"
            >
                {drawerItems.map((item) => {
                    const IconComponent = item.icon
                    return (
                        <Tooltip
                            key={`desktop-${item.title}`}
                            title={item.title}
                            disableHoverListener={props.open}
                            placement="right"
                        >
                            <ListItem disablePadding>
                                <ListItemButton component={NavLink} to={item.link}>
                                    <ListItemIcon>
                                        <IconComponent />
                                    </ListItemIcon>
                                    {props.open && <ListItemText primary={item.title} />}
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    )
                })}
                {accessBasedDrawerItems.map((item) => {
                    const IconComponent = item.icon
                    return (
                        <Tooltip
                            key={`desktop-${item.title}`}
                            title={item.title}
                            disableHoverListener={props.open}
                            placement="right"
                        >
                            <ListItem disablePadding>
                                <ListItemButton component={NavLink} to={item.link}>
                                    <ListItemIcon>
                                        <IconComponent />
                                    </ListItemIcon>
                                    {props.open && <ListItemText primary={item.title} />}
                                </ListItemButton>
                            </ListItem>
                        </Tooltip>
                    )
                })}
            </List>
            <Divider />

            {isOpusAdmin(auth) || isCustomerAdmin(auth) ? (
                <List
                    key="admin"
                    sx={{
                        ['& .active']: {
                            background: theme.palette.divider,
                        },
                    }}
                >
                    {adminDrawerItems.map((item) => {
                        const IconComponent = item.icon
                        return (
                            <Tooltip
                                key={`admin-${item.title}`}
                                title={item.title}
                                disableHoverListener={props.open}
                                placement="right"
                            >
                                {item.link ? (
                                    <ListItem disablePadding>
                                        <ListItemButton component={NavLink} to={item.link}>
                                            <ListItemIcon>
                                                <IconComponent />
                                            </ListItemIcon>
                                            {props.open && <ListItemText primary={item.title} />}
                                        </ListItemButton>
                                    </ListItem>
                                ) : item.onClick ? (
                                    <ListItem disablePadding>
                                        <ListItemButton component={Link} onClick={item.onClick}>
                                            <ListItemIcon>
                                                <IconComponent />
                                            </ListItemIcon>
                                            {props.open && <ListItemText primary={item.title} />}
                                        </ListItemButton>
                                    </ListItem>
                                ) : (
                                    <ListItem disablePadding>
                                        <ListItemButton component={Link} onClick={item.onClick}>
                                            <ListItemIcon>
                                                <IconComponent />
                                            </ListItemIcon>
                                            {props.open && <ListItemText primary={item.title} />}
                                        </ListItemButton>
                                    </ListItem>
                                )}
                            </Tooltip>
                        )
                    })}
                </List>
            ) : null}
            {isOpusAdmin(auth) ? <Divider /> : null}
        </Drawer>
    )
}

export default MainNavigation
